.tea-spotlight {
    display: flex;
    justify-content: space-between;

    padding-top: min(100px, 2vh); /* 177 - 77 */
    width: min(1235px, 100%);
}

.tea-details {
    width: 100%;
}

.tea-spotlight > .tea-details {
    float: right;
}

.tea-details > .tea-name {
    font-weight: 700;
    font-size: min(65px, 5vh);
    line-height: 110%;
    padding-top: min(77px, 5vh);
}

.tea-details > .tea-tags {
    font-weight: 700;
    font-size: min(35px, 3vh);
    line-height: 110%;
    padding-top: min(30px, 2vh);
}

.tea-details > .tea-description {
    font-weight: 400;
    font-size: min(25px, 2.5vh);
    line-height: 170%;
    padding-top: min(21px, 2vh);
}

.tea-details .suggested-match, .tea-details .tea-rank, .tea-details .match-history {
    font-weight: 700;
    font-size: min(25px, 2.5vh);
    line-height: 120%;
    padding-top: min(21px, 1.5vh);
}

.tea-details a:link, .tea-details a:visited {
    color: #282828;
    text-decoration: none;
}

.tea-details a:hover {
    text-decoration: underline;
    text-decoration-color: #0094FF;
    text-underline-offset: 0.1em;
    text-decoration-thickness: 3px;
}

.tea-spotlight > .tea-img {
    float: right;
    z-index: -1;
}

.tea-spotlight > .tea-img > img {
    width: 545px;
}

@media (max-width: 1235px) {
    .tea-spotlight > .tea-img {
        position: fixed;
    }

    .tea-spotlight > .tea-img > img {
        opacity: 0.15;
    }
}